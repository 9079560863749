var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "mb-5", attrs: { fluid: "" } },
    [
      _vm.layout && _vm.widgets
        ? _c(
            "b-row",
            { staticClass: "dashboard-row" },
            [
              _vm._l(_vm.layout, function(widget, index) {
                return _c(
                  "b-col",
                  {
                    key: index,
                    staticClass: "dashboard-col mb-3",
                    class: _vm.getWidgetClass(_vm.getWidgetWidth(widget)),
                    style: _vm.getWidgetHeight(widget)
                      ? "max-height:" +
                        _vm.getWidgetHeight(widget) +
                        ";min-height:" +
                        _vm.getWidgetHeight(widget)
                      : ""
                  },
                  [
                    _vm.filters && _vm.filtersAreValid
                      ? _c(widget.report, {
                          tag: "container",
                          staticClass: "dashboard-widget",
                          attrs: {
                            report: _vm.getWidgetInfo(widget),
                            index: index,
                            filters: _vm.filters
                          },
                          on: {
                            dashboardReportSetDisplay: function(data) {
                              return _vm.$emit(
                                "dashboardReportSetDisplay",
                                data
                              )
                            },
                            refresh: function($event) {
                              return _vm.$emit("refresh")
                            }
                          }
                        })
                      : _c("b-skeleton-img", {
                          attrs: { animation: "", "no-aspect": "" }
                        })
                  ],
                  1
                )
              }),
              !_vm.filtersAreValid
                ? _c("div", { staticClass: "h1 invalid-filters text--white" }, [
                    _vm._v(" Please select a "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !this.filters || !+this.filters.company_id > 0,
                            expression:
                              "!this.filters || !+this.filters.company_id > 0"
                          }
                        ]
                      },
                      [_vm._v(" company")]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !this.filters ||
                              (!+this.filters.company_id > 0 &&
                                !this.filters.startDate),
                            expression:
                              "!this.filters || (!+this.filters.company_id > 0 && !this.filters.startDate)"
                          }
                        ]
                      },
                      [_vm._v(" and")]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !this.filters || !this.filters.startDate,
                            expression:
                              "!this.filters || !this.filters.startDate"
                          }
                        ]
                      },
                      [_vm._v(" date range")]
                    )
                  ])
                : _vm._e()
            ],
            2
          )
        : _c(
            "b-row",
            { staticClass: "dashboard-row" },
            [
              _vm._l(4, function(n) {
                return _c(
                  "b-col",
                  {
                    key: n,
                    staticClass: "dashboard-col dashboard-item mb-3",
                    class: _vm.getWidgetClass(1)
                  },
                  [
                    _c("b-skeleton-img", {
                      attrs: { animation: "", "no-aspect": "" }
                    })
                  ],
                  1
                )
              }),
              _c(
                "b-col",
                {
                  staticClass: "dashboard-col dashboard-item mb-3",
                  class: _vm.getWidgetClass(2)
                },
                [
                  _c("b-skeleton-img", {
                    attrs: { animation: "", "no-aspect": "" }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "dashboard-col dashboard-item mb-3",
                  class: _vm.getWidgetClass(3)
                },
                [
                  _c("b-skeleton-img", {
                    attrs: { animation: "", "no-aspect": "" }
                  })
                ],
                1
              )
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }