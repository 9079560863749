var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "6" } }, [_vm._v(" Widget ")]),
          _c("b-col", { staticClass: "text-center", attrs: { cols: "2" } }, [
            _vm._v(" Graph ")
          ]),
          _c("b-col", { staticClass: "text-center", attrs: { cols: "2" } }, [
            _vm._v(" Table ")
          ])
        ],
        1
      ),
      _c("hr", { staticStyle: { "border-top": "1px solid white" } }),
      _vm._l(_vm.widgets, function(w) {
        return _c(
          "b-row",
          { key: w.id },
          [
            _c("b-col", { attrs: { cols: "6" } }, [
              _vm._v(" " + _vm._s(w.name) + " ")
            ]),
            _c(
              "b-col",
              { staticClass: "text-center", attrs: { cols: "2" } },
              [
                _vm.checkForType(w, "graph")
                  ? _c("b-checkbox", {
                      attrs: { value: { report: w.report, display: "graph" } },
                      model: {
                        value: _vm.widgetsToAdd,
                        callback: function($$v) {
                          _vm.widgetsToAdd = $$v
                        },
                        expression: "widgetsToAdd"
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "b-col",
              { staticClass: "text-center", attrs: { cols: "2" } },
              [
                _vm.checkForType(w, "table")
                  ? _c("b-checkbox", {
                      attrs: { value: { report: w.report, display: "table" } },
                      model: {
                        value: _vm.widgetsToAdd,
                        callback: function($$v) {
                          _vm.widgetsToAdd = $$v
                        },
                        expression: "widgetsToAdd"
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }