var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "mb-4 sticky-filters" },
        [
          _c(
            "b-col",
            [
              _c("DashboardFilter", {
                attrs: { filters: _vm.filters, widgets: _vm.widgets },
                on: {
                  dateFilterChange: _vm.updateDateFilter,
                  compareFilterChange: _vm.updateCompDateFilter,
                  refresh: function($event) {
                    return _vm.refresh()
                  },
                  reset: function($event) {
                    return _vm.resetFilters()
                  },
                  saveFilters: _vm.saveFilters
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm.contracttype_id !== 10 ||
      (_vm.contracttype_id === 10 && _vm.user.isAdmin) ||
      (_vm.contracttype_id === 10 && _vm.user.isBookkeeper)
        ? _c(
            "div",
            [
              _vm.lastsync
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        color: "white",
                        "font-size": "medium",
                        "text-align": "center"
                      }
                    },
                    [
                      _c("small", [
                        _vm._v("Last Synced: " + _vm._s(_vm.lastsync))
                      ])
                    ]
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c("DashboardLayout", {
                        attrs: {
                          widgets: _vm.widgets,
                          layout: _vm.layout,
                          filters: _vm.filters
                        },
                        on: {
                          dashboardReportSetDisplay: _vm.reportSetDisplay,
                          refresh: function($event) {
                            return _vm.refresh()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-card",
                    { attrs: { title: "Dashboard Restricted." } },
                    [
                      _c("b-card-text", [
                        _vm._v(
                          "This is an example dashboard that presents charts and graphs displaying your pertinent financial information in a quick visual display, giving you an informative snapshot of your businesses key performance indicators. The dashboard is a premium feature for the Entrepreneur Package. If you are interested in learning more about the dashboard and how you can get it, please contact "
                        ),
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#BE191E" },
                            attrs: {
                              href: "mailto:sales@streamlinedbookkeeping.com"
                            }
                          },
                          [_vm._v("sales@streamlinedbookkeeping.com")]
                        )
                      ]),
                      _c("b-card-img", {
                        attrs: {
                          src: "/img/logo/Dash_Example.png",
                          alt: "Image",
                          bottom: ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }