var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-bar p-2", staticStyle: { "text-align": "start" } },
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "xl" } },
        [
          _c("b-navbar-toggle", { attrs: { target: "filter-collapse" } }),
          _c(
            "b-collapse",
            { attrs: { id: "filter-collapse", "is-nav": "" } },
            [
              _c(
                "button",
                {
                  staticClass: "filter-btn reset",
                  on: {
                    click: function($event) {
                      return _vm.reset()
                    }
                  }
                },
                [_vm._v("Reset Filters")]
              ),
              _c("b-form-select", {
                staticClass: "select company",
                attrs: {
                  options: _vm.companies,
                  "text-field": "name",
                  "value-field": "id",
                  plain: ""
                },
                model: {
                  value: _vm.myFilters.company_id,
                  callback: function($$v) {
                    _vm.$set(_vm.myFilters, "company_id", $$v)
                  },
                  expression: "myFilters.company_id"
                }
              }),
              _c("date-range-picker", {
                staticClass: "select daterange",
                attrs: {
                  opens: "center",
                  "single-date-picker": "range",
                  "show-dropdowns": true,
                  "auto-apply": true,
                  ranges: _vm.ranges
                },
                on: {
                  update: function($event) {
                    return _vm.updateDates()
                  },
                  startSelection: function($event) {
                    _vm.selectionStep = 2
                  },
                  finishSelection: function($event) {
                    _vm.selectionStep = 0
                  },
                  toggle: function($e) {
                    _vm.selectionStep = $e ? 1 : 0
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "input",
                    fn: function(picker) {
                      return [
                        _c("span", [
                          _c(
                            "span",
                            {
                              staticClass: "mr-1",
                              style:
                                _vm.selectionStep == 1
                                  ? "font-weight: bold;"
                                  : ""
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("date")(picker.startDate)) +
                                  " "
                              )
                            ]
                          ),
                          _vm._v(" to "),
                          _c(
                            "span",
                            {
                              staticClass: "ml-1",
                              style:
                                _vm.selectionStep == 2
                                  ? "font-weight: bold;"
                                  : ""
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm._f("date")(picker.endDate)) +
                                  " "
                              )
                            ]
                          )
                        ])
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.dateRange,
                  callback: function($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange"
                }
              }),
              _c("date-range-picker", {
                staticClass: "select daterange",
                attrs: {
                  opens: "center",
                  "single-date-picker": "range",
                  "show-dropdowns": true,
                  "auto-apply": true,
                  ranges: _vm.ranges
                },
                on: {
                  update: function($event) {
                    return _vm.updateCompareDates()
                  },
                  startSelection: function($event) {
                    _vm.compSelectionStep = 2
                  },
                  finishSelection: function($event) {
                    _vm.compSelectionStep = 0
                  },
                  toggle: function($e) {
                    _vm.compSelectionStep = $e ? 1 : 0
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "input",
                    fn: function(picker) {
                      return [
                        _vm.compSelectionStep > 0 || picker.startDate
                          ? _c("span", [
                              _c(
                                "span",
                                {
                                  staticClass: "mr-1",
                                  style:
                                    _vm.compSelectionStep == 1
                                      ? "font-weight: bold;"
                                      : ""
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("date")(picker.startDate)) +
                                      " "
                                  )
                                ]
                              ),
                              _vm._v(" to "),
                              _c(
                                "span",
                                {
                                  staticClass: "ml-1",
                                  style:
                                    _vm.compSelectionStep == 2
                                      ? "font-weight: bold;"
                                      : ""
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm._f("date")(picker.endDate)) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          : _c("span", [_vm._v(" Compare to date range ")])
                      ]
                    }
                  },
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _vm.compareRange.startDate
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  block: "",
                                  variant: "danger",
                                  size: "sm"
                                },
                                on: { click: _vm.clearCompare }
                              },
                              [_vm._v("Clear")]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.compareRange,
                  callback: function($$v) {
                    _vm.compareRange = $$v
                  },
                  expression: "compareRange"
                }
              }),
              _c("div", { staticClass: "filter-btn-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "filter-btn left",
                    class: _vm.myFilters.period == "month" ? "selected" : "",
                    on: {
                      click: function($event) {
                        _vm.myFilters.period = "month"
                      }
                    }
                  },
                  [_vm._v(" Month ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "filter-btn right",
                    class: _vm.myFilters.period == "quarter" ? "selected" : "",
                    on: {
                      click: function($event) {
                        _vm.myFilters.period = "quarter"
                      }
                    }
                  },
                  [_vm._v(" Quarter ")]
                )
              ]),
              _c("div", { staticClass: "filter-btn-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "filter-btn left",
                    class: _vm.myFilters.type == "all" ? "selected" : "",
                    on: {
                      click: function($event) {
                        _vm.myFilters.type = "all"
                      }
                    }
                  },
                  [_vm._v(" All ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "filter-btn right",
                    class: _vm.myFilters.type == "direct" ? "selected" : "",
                    on: {
                      click: function($event) {
                        _vm.myFilters.type = "direct"
                      }
                    }
                  },
                  [_vm._v(" Direct ")]
                )
              ]),
              _c("div", { staticClass: "filter-btn-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "filter-btn left",
                    class: _vm.myFilters.display == "percent" ? "selected" : "",
                    on: {
                      click: function($event) {
                        _vm.myFilters.display = "percent"
                      }
                    }
                  },
                  [_vm._v(" % ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "filter-btn right",
                    class: _vm.myFilters.display == "money" ? "selected" : "",
                    on: {
                      click: function($event) {
                        _vm.myFilters.display = "money"
                      }
                    }
                  },
                  [_vm._v(" $ ")]
                )
              ]),
              _c(
                "div",
                { staticClass: "settings-cog-box" },
                [
                  _c("b-icon-gear-wide-connected", {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal",
                        value: "addWidgets",
                        expression: "'addWidgets'"
                      }
                    ],
                    staticClass: "h3 my-auto"
                  })
                ],
                1
              ),
              _c(
                "b-modal",
                {
                  attrs: {
                    id: "addWidgets",
                    title: "Add Widgets",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                    "ok-title": "Save"
                  },
                  on: {
                    ok: _vm.handleSave,
                    show: function($event) {
                      _vm.widgetsToAdd = []
                    },
                    hidden: function($event) {
                      _vm.widgetsToAdd = []
                    }
                  }
                },
                [
                  _c("report-selector", {
                    attrs: { widgets: _vm.widgets },
                    on: { update: _vm.newWidgets }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }