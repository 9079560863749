var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ReportCard",
    {
      attrs: {
        report: _vm.report,
        index: _vm.index,
        loading: _vm.loading,
        filters: _vm.filters
      },
      on: {
        dashboardReportSetDisplay: function(data) {
          return _vm.$emit("dashboardReportSetDisplay", data)
        },
        refresh: function($event) {
          return _vm.$emit("refresh")
        }
      }
    },
    [
      this.data && this.data.data && this.report.display === "graph"
        ? _c(
            "div",
            { staticClass: "chart-container" },
            [
              _c("BarChart", {
                attrs: {
                  data: _vm.formatChartData(_vm.data),
                  options: _vm.options
                }
              })
            ],
            1
          )
        : this.data && this.data.data && this.report.display === "table"
        ? _c("div", { staticClass: "table-responsive" }, [
            _c(
              "table",
              { staticClass: "table table-striped" },
              [
                _c("thead", [
                  _c(
                    "tr",
                    [
                      _c("th"),
                      _vm._l(_vm.data.data[0].labels, function(l, idx) {
                        return _c("th", { key: idx }, [
                          _vm._v(" " + _vm._s(l) + " ")
                        ])
                      })
                    ],
                    2
                  )
                ]),
                _vm._l(_vm.data.data, function(d, didx) {
                  return _c("tbody", { key: didx }, [
                    _c(
                      "tr",
                      [
                        _c("td", [_vm._v(_vm._s(d.label))]),
                        _vm._l(d.data, function(v, idx) {
                          return _c("td", { key: idx }, [
                            !v.COGS && !v.Expenses
                              ? _c("div", [
                                  _vm._v(
                                    " " + _vm._s(_vm._f("currency")(v)) + " "
                                  )
                                ])
                              : _c("div", [
                                  _c("div", [
                                    _c("strong", [_vm._v("COGS: ")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(v.COGS.value)
                                        ) +
                                        " "
                                    ),
                                    _c("strong", [
                                      _vm._v(_vm._s(v.COGS.percent) + "%")
                                    ])
                                  ]),
                                  _c("br"),
                                  _c("div", [
                                    _c("strong", [_vm._v("Expenses: ")]),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(v.Expenses.value)
                                        ) +
                                        " "
                                    ),
                                    _c("strong", [
                                      _vm._v(_vm._s(v.Expenses.percent) + "%")
                                    ])
                                  ])
                                ])
                          ])
                        })
                      ],
                      2
                    )
                  ])
                })
              ],
              2
            )
          ])
        : _c("div", [_c("BarChart")], 1)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }