var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ReportCard",
    {
      attrs: {
        report: _vm.report,
        index: _vm.index,
        filters: _vm.filters,
        loading: _vm.loading
      },
      on: {
        dashboardReportSetDisplay: function(data) {
          return _vm.$emit("dashboardReportSetDisplay", data)
        },
        refresh: function($event) {
          return _vm.$emit("refresh")
        }
      }
    },
    [
      this.data && this.report.display === "graph"
        ? _c(
            "div",
            { staticClass: "chart-container" },
            [
              _c("DonutChart", {
                attrs: {
                  data: _vm.formatChartData(_vm.data),
                  options: _vm.options
                }
              })
            ],
            1
          )
        : this.data && this.report.display === "table"
        ? _c(
            "div",
            {
              staticClass: "table-responsive h-100",
              attrs: { data: _vm.formatChartData(_vm.data) }
            },
            [
              _c(
                "div",
                {
                  staticClass: "small h-100",
                  staticStyle: { overflow: "auto" }
                },
                [
                  _c(
                    "table",
                    {
                      staticClass:
                        "table table-striped text-left table-condensed table-sm"
                    },
                    [
                      _c("thead", [
                        _c("tr", [
                          _c("th"),
                          _c("th", [_vm._v("Vendor")]),
                          _c("th", { staticClass: "text-right" }, [
                            _vm._v("Total")
                          ])
                        ])
                      ]),
                      _c(
                        "tbody",
                        _vm._l(_vm.data.data[0].data, function(v, idx) {
                          return _c("tr", { key: idx }, [
                            _c(
                              "td",
                              {
                                style:
                                  "background-color: " + _vm.getBgColor(idx)
                              },
                              [_c("span", [_vm._v("  ")])]
                            ),
                            _c("td", [
                              _vm._v(_vm._s(_vm.data.data[0].labels[idx]))
                            ]),
                            _c("td", { staticClass: "text-right" }, [
                              _vm.filters && _vm.filters.display === "percent"
                                ? _c("span", [_vm._v(_vm._s(v) + "%")])
                                : _c("span", [
                                    _vm._v(_vm._s(_vm._f("currency")(v)))
                                  ])
                            ])
                          ])
                        }),
                        0
                      )
                    ]
                  )
                ]
              )
            ]
          )
        : _c("div", [_vm._v(" No data to display ")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }