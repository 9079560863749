var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-100" }, [
    _vm.data && _vm.data.data
      ? _c(
          "div",
          { staticClass: "h-100", staticStyle: { color: "black" } },
          [
            _c(
              "b-row",
              { staticClass: "h-100", attrs: { "align-v": "center" } },
              [
                _c("b-col", { attrs: { cols: "12" } }, [
                  _c("span", { staticClass: "h1" }, [
                    _vm._v(_vm._s(_vm.data.data.label))
                  ]),
                  _vm.data.data.value && _vm.data.data.value.length > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _vm.data.data.value.length > 1
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.data.data.value[0].label)
                                      )
                                    ])
                                  : _vm._e(),
                                _c("div", { staticClass: "h1" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.data.data.value[0].value) +
                                      " "
                                  )
                                ]),
                                _vm.data.data.value[1]
                                  ? _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.data.data.value[1].label)
                                        )
                                      ]),
                                      _c("div", { staticClass: "h1" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.data.data.value[1].value
                                            ) +
                                            " "
                                        )
                                      ])
                                    ])
                                  : _vm._e(),
                                _vm.data.data.value[2]
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "h1",
                                          style:
                                            "color: " +
                                            _vm.getColor(
                                              +_vm.data.data.value[2].value
                                            )
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("showsign")(
                                                  _vm.data.data.value[2].value
                                                )
                                              ) +
                                              "% "
                                          ),
                                          _c("b-icon", {
                                            style:
                                              "transform: rotate(" +
                                              _vm.getAngle(
                                                _vm.data.data.value[2].value
                                              ) +
                                              "deg)",
                                            attrs: { icon: "arrow-right" }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ],
          1
        )
      : _c("div", { staticStyle: { color: "black" } }, [
          _vm._v(" No data to display ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }