var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        _vm._l(_vm.layout, function(widget, index) {
          return _c(
            "b-col",
            {
              key: index,
              class: _vm.getWidgetClass(_vm.getWidgetWidth(widget))
            },
            [
              index != _vm.location && _vm.location + 1 != index
                ? _c("div", {
                    staticClass: "leftBarHover hidden-box left",
                    style: _vm.getLocation(index, "left"),
                    on: {
                      click: function($event) {
                        return _vm.moveTo(index, -1)
                      }
                    }
                  })
                : _vm._e(),
              index != _vm.location && _vm.location - 1 != index
                ? _c("div", {
                    staticClass: "rightBarHover hidden-box right",
                    style: _vm.getLocation(index, "right"),
                    on: {
                      click: function($event) {
                        return _vm.moveTo(index, 1)
                      }
                    }
                  })
                : _vm._e(),
              index == _vm.location
                ? _c("div", {
                    staticClass: "delete",
                    on: {
                      click: function($event) {
                        return _vm.remove()
                      }
                    }
                  })
                : _vm._e(),
              _c(
                "h3",
                {
                  staticClass: "text-center mini-dash-col",
                  style:
                    _vm.location == index
                      ? "color: red; border-color: red;"
                      : "color: black"
                },
                [
                  _vm.location == index
                    ? _c("b-icon-trash-fill")
                    : _c("span", [_vm._v(_vm._s(index + 1))])
                ],
                1
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }