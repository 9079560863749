var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ReportCard",
    {
      attrs: {
        report: _vm.report,
        index: _vm.index,
        filters: _vm.filters,
        loading: _vm.loading
      },
      on: {
        dashboardReportSetDisplay: function(data) {
          return _vm.$emit("dashboardReportSetDisplay", data)
        },
        refresh: function($event) {
          return _vm.$emit("refresh")
        }
      }
    },
    [_c("Box", { attrs: { data: _vm.data, reverse: "" } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }