var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.report
    ? _c(
        "b-card",
        {
          staticClass: "p-0 h-100 report",
          scopedSlots: _vm._u(
            [
              {
                key: "header",
                fn: function() {
                  return [
                    _c(
                      "b-row",
                      {
                        staticClass: "justify-content-between m-0 p-0",
                        attrs: { "no-gutters": "", "align-v": "center" }
                      },
                      [
                        _c(
                          "b-col",
                          { staticClass: "text-left", attrs: { cols: "1" } },
                          [
                            _vm.report.defaults &&
                            _vm.report.defaults.display.length > 1
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "type",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { title: "Toggle View" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.toggleDisplay.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon:
                                          _vm.report.display === "graph"
                                            ? "graph-up"
                                            : "table"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        ),
                        _c(
                          "b-col",
                          [
                            _c("b-card-title", { staticClass: "m-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.report.defaults.name) + " "
                              ),
                              _vm.report.defaults.details
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "d-inline-block",
                                      staticStyle: { transform: "scale(0.8)" }
                                    },
                                    [
                                      _c("b-icon", {
                                        directives: [
                                          {
                                            name: "b-popover",
                                            rawName: "v-b-popover.hover.click",
                                            value: _vm.report.defaults.details,
                                            expression:
                                              "report.defaults.details",
                                            modifiers: {
                                              hover: true,
                                              click: true
                                            }
                                          }
                                        ],
                                        attrs: { icon: "info-circle-fill" }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ])
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { staticClass: "text-right", attrs: { cols: "1" } },
                          [
                            _c("b-icon-arrows-move", {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal",
                                  value: "moveModal" + _vm.index,
                                  expression: "'moveModal' + index"
                                }
                              ],
                              staticClass: "move"
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            3960456880
          )
        },
        [
          _c(
            "b-modal",
            {
              attrs: {
                id: "moveModal" + _vm.index,
                title: "Move " + _vm.report.defaults.name,
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                "ok-title": "Save"
              },
              on: {
                ok: _vm.handleSave,
                show: function($event) {
                  _vm.tempLayout = []
                },
                hidden: function($event) {
                  _vm.tempLayout = []
                }
              }
            },
            [
              _c("move", {
                attrs: { item: _vm.index, filters: _vm.filters },
                on: {
                  layoutChange: function($event) {
                    return _vm.saveTempLayout($event, 0)
                  },
                  deleted: function($event) {
                    return _vm.saveTempLayout($event, -1)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "b-overlay",
            {
              staticClass: "h-100",
              attrs: {
                show: _vm.loading,
                id: "report_" + +_vm.index,
                rounded: "sm"
              }
            },
            [_vm._t("default")],
            2
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }